import "./login.scss";

export const Login = () => {
  return (
    <div className="login">
      <div className="title">Login</div>
    </div>
  );
};

export default Login;
